import { createApp } from 'vue';
import { IconFont } from '@nutui/icons-vue';

import './style.css';
import '@nutui/icons-vue/dist/style_iconfont.css';
import '@nutui/nutui/dist/packages/toast/style';
import '@nutui/nutui/dist/packages/dialog/style';
import '@nutui/nutui/dist/packages/imagepreview/style';
import '@nutui/nutui/dist/packages/notify/style';
import 'amfe-flexible'

import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(router).use(IconFont).mount('#app');
