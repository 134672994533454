const __pages_import_0__ = () => import("/src/views/password/edit/index.vue");
const __pages_import_1__ = () => import("/src/views/edit/info/index.vue");
const __pages_import_2__ = () => import("/src/views/edit/category/index.vue");
const __pages_import_3__ = () => import("/src/views/edit/account/index.vue");
const __pages_import_4__ = () => import("/src/views/add/category/index.vue");
const __pages_import_5__ = () => import("/src/views/add/account/index.vue");
const __pages_import_6__ = () => import("/src/views/register/index.vue");
const __pages_import_7__ = () => import("/src/views/login/index.vue");
const __pages_import_8__ = () => import("/src/views/agreement/index.vue");
const __pages_import_9__ = () => import("/src/views/admin.vue");
const __pages_import_10__ = () => import("/src/views/admin/mine/index.vue");
const __pages_import_11__ = () => import("/src/views/admin/home/index.vue");
const __pages_import_12__ = () => import("/src/views/admin/category/index.vue");

const routes = [{"name":"password-edit","path":"/password/edit","component":__pages_import_0__,"props":true},{"name":"edit-info","path":"/edit/info","component":__pages_import_1__,"props":true},{"name":"edit-category","path":"/edit/category","component":__pages_import_2__,"props":true},{"name":"edit-account","path":"/edit/account","component":__pages_import_3__,"props":true},{"name":"add-category","path":"/add/category","component":__pages_import_4__,"props":true},{"name":"add-account","path":"/add/account","component":__pages_import_5__,"props":true},{"name":"register","path":"/register","component":__pages_import_6__,"props":true},{"name":"login","path":"/login","component":__pages_import_7__,"props":true},{"name":"agreement","path":"/agreement","component":__pages_import_8__,"props":true},{"name":"admin","path":"/admin","component":__pages_import_9__,"children":[{"name":"admin-mine","path":"/mine","component":__pages_import_10__,"props":true},{"name":"admin-home","path":"/","component":__pages_import_11__,"props":true},{"name":"admin-category","path":"/category","component":__pages_import_12__,"props":true}],"props":true}];

export default routes;