import { createRouter, createWebHistory } from 'vue-router';
import routes from '~pages';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const notChecked = ['/login', '/register', '/forget','/agreement'];
router.beforeEach((to, _from, next) => {
  const token = localStorage.getItem('token');
  if(notChecked.includes(to.path)){
    return next();
  }
  if (token) {
    return next();
  }
  return next('/login');
});

export default router
